







































  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
import { ServerResponse } from '@/services/response.types';
  import { ProductMobilState } from '@/store/product/mobil/types';
import { ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'productMobil',
    components: {
      compareCar: () => import('@/components/formCompare/compareCar.vue'),
      productHelp: () => import('@/components/ProductHelp.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class productMobil extends Vue {
    @State((state: ProductMobilState) => state.title.HTMLtoString(), { namespace: 'product/mobil' }) title!: string;
    @State((state: ProductMobilState) => state.content.HTMLtoString(), { namespace: 'product/mobil' }) content!: string;
    // @State((state: ProductMobilState) => state.banner.HTMLtoString(), { namespace: 'product/mobil' }) banner!: string;
    @State('page_content') page_content!: ContentModel[];

    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'mobil-banner')[0]?.image ?? null;
    }

    public get helpImg(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'mobil-help')[0]?.image ?? null;
    }

    public get information() : ContentModel {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'mobil-information')[0]??new ContentModel();
    }

    public bannerDefault = require('@/assets/images/banner/mobil.jpg');

    public get listBannerInfo() :ContentModel[] {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.SLIDER && item.name === 'mobil-slider')
    }

    public created(): void {}
    public mounted(): void {
      // on componenet mounted
    }
  }
